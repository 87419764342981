import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, EffectCreative } from 'swiper'
import styled from 'styled-components'

import 'swiper/css'
import "swiper/css/navigation"
import "swiper/css/effect-creative"

import './App.css'

// install Swiper modules
SwiperCore.use([Navigation, EffectCreative]);

interface IMusic {
  id: number,
  name: string,
  filename: string;
  image: string;
};

const music: IMusic[] = [
  {
    id: 1,
    name: 'Fire',
    filename: '/sounds/fire.mp3',
    image: 'relax/fire_g7hXkwBuQ.jpg'
  },
  {
    id: 2,
    name: 'Bird songs in forest',
    filename: '/sounds/forest.mp3',
    image: 'relax/forest_lXb5bApI-2H.jpg',
  },
  {
    id: 3,
    name: 'Ocean waves',
    filename: '/sounds/ocean.mp3',
    image: 'relax/ocean_NlAkQdvEh.jpg',
  },
  {
    id: 4,
    name: 'Rain',
    filename: '/sounds/rain.mp3',
    image: 'relax/rain_dJ0Hl4est.jpeg',
  },
  {
    id: 5,
    name: 'Pug Semen snoring',
    filename: '/sounds/pug_sleep.mp3',
    image: 'relax/pug_X5hIj9yZE.jpeg',
  },
];

const SlideDesc = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

interface ISlideImage {
  src: string;
  blured?: boolean;
  [key: string]: any;
}

const PictureCore = styled.picture`
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  background: inherit;
`;

const ImageCore = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SlideImage: React.FC<ISlideImage> = ({src, blured, ...props}) => (
  <PictureCore>
    <source srcSet={`https://ik.imagekit.io/sbodyagin/tr:w-1280${blured && ',bl-10'}/${src}`} media="(min-width: 768px)" />
    <source srcSet={`https://ik.imagekit.io/sbodyagin/tr:w-768${blured && ',bl-10'}/${src}`} media="(min-width: 480px)" />
    <ImageCore src={`https://ik.imagekit.io/sbodyagin/tr:w-480${blured && ',bl-10'}/${src}`} {...props} />
  </PictureCore>
);

interface IActionHandler {
  action: MediaSessionAction;
  handler: MediaSessionActionHandler | null;
}


function App() {
  const [saved, setSaved] = useState(() => {
    const saved = localStorage.getItem('saved');
    const initialValue = JSON.parse(saved || '{}');
    return initialValue || {};
  });
  useEffect(() => {
    localStorage.setItem('saved', JSON.stringify(saved));
  }, [saved]);

  const [track, setTrack] = useState<number>(2);

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    playing ? audio?.play() : audio?.pause();
  }, [playing]);

  const [soundStarted, setSoundStarted] = useState(false);
  // const [loadedCount, setLoadedCount] = useState(0);
  const play = async () => {
    setSoundStarted(true);
  }
  const pause = (a?: HTMLAudioElement) => {
    console.log('pause all', audio);
    a?.pause();
    audio?.pause();
    setSoundStarted(false);
  }

  const setIndex = async (index: number, swiper: SwiperCore) => {
    console.log("play " + music[index].name, swiper);
    audio?.pause();

    const newAudio = new Audio(music[index].filename);
    newAudio.crossOrigin = 'anonymous';
    if (typeof newAudio.loop === 'boolean') {
      newAudio.loop = true;
    } else {
      newAudio.addEventListener('ended', function() {
        this.currentTime = 0;
        this.play();
      }, false);
    }

    if ("mediaSession" in navigator) {
      const session = navigator.mediaSession;
      session.metadata = new MediaMetadata({
        title: music[index].name,
        artist: 'Background sound for work, rest and home',
        album: 'Relax',
        artwork: [
          { src: `https://ik.imagekit.io/sbodyagin/tr:w-512,h-512,fo-bottom,f-png/${music[index].image}`, sizes: '512x512', type: 'image/png' },
        ]
      });
    
      const actionHandlers: IActionHandler[] = [
        { action: 'previoustrack', handler: () => swiper.slidePrev()},
        { action: 'play',          handler: () => play()},
        { action: 'pause',         handler: () => pause(newAudio)},
        { action: 'nexttrack',     handler: () => swiper.slideNext()},
      ];
      
      for (const {action, handler} of actionHandlers) {
        try {
          session.setActionHandler(action, handler);
        } catch (error) {
          console.log(`The media session action "${action}" is not supported yet.`);
        }
      }

      newAudio.addEventListener('play', function() {
        console.log('playback - play');
        navigator.mediaSession.playbackState = 'playing';
      });
      
      newAudio.addEventListener('pause', function() {
        console.log('playback - pause');
        navigator.mediaSession.playbackState = 'paused';
      });
    }
  
    setAudio(newAudio);
    await newAudio.play();
    setTrack(swiper.activeIndex);
  }

  const toggleSaved = async (music: IMusic) => {
    const url = process.env.PUBLIC_URL + music.filename;
    const cache = await caches.open('music');

    if (saved[music.name] === true) {
      await cache.delete(url);
      setSaved({...saved, [music.name]: false, });
    } else {
      try {
        await cache.add(url);
        setSaved({...saved, [music.name]: true, });  
      } catch {}
    }
  }









  const slides = music.map((m, i) => (
    <SwiperSlide key={`slide-${m.name}`}>
      <SlideImage src={m.image} alt='' />
      {/* <img src={`images/icons/${saved[m.name] === true ? 'saved' : 'save'}.svg`} style={{position: 'absolute', bottom: '10px', left: '10px', width: '25%', maxWidth: '75px', backgroundColor: '#fff', borderRadius: '25%', padding: '10px', cursor: 'pointer', }} onClick={() => toggleSaved(m)} alt='' /> */}
      <img src='images/icons/pause.svg' style={{position: 'absolute', bottom: '10px', right: '10px', width: '25%', maxWidth: '75px', backgroundColor: '#fff', borderRadius: '25%', padding: '10px', cursor: 'pointer', }} onClick={() => pause()} alt='' />
    </SwiperSlide>
  ));

  return (
    <Container>
      { !soundStarted &&
        <div style={{width: '100%', height: '100%'}} onClick={() => setSoundStarted(true)}>
          <SlideImage blured={true} src='relax/candle_cZYoMhW0v.jpg' />
          <SlideDesc>
            <h1 className="header" color="#fff">Включите звук</h1>
            <img src='images/icons/play.svg' style={{width: '50%', maxWidth: '200px', animation: 'pulse 1s linear infinite', }} />
            <h1 className="header" color="#fff">и нажмите</h1>
          </SlideDesc>
        </div>
      }

      {/* { soundStarted && !isLoaded &&
        <div style={{width: '100%', height: '100%'}}>
          <SlideImage blured={true} src='relax/candle_cZYoMhW0v.jpg' />
          <SlideDesc>
            <h1 className="header" color="#fff">Загрузка звуков</h1>
          </SlideDesc>
        </div>
      } && isLoaded */}

      { soundStarted &&
        <Swiper
          id='swiper'
          slidesPerView={1.25}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          navigation={true}
          grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            "prev": {
              "shadow": true,
              "translate": ["-105%", 0, -400]
            },
            "next": {
              "shadow": true,
              "translate": ["105%", 0, -400]
            }
          }}
          className='swiper'
          onSlideChange={(swiper) => setIndex((music.length + swiper.activeIndex - 2) % music.length, swiper)}
          initialSlide={(track - 2) % music.length}
        >
          {slides}
        </Swiper>
      }
    </Container>
  );
}

export default App;
